import React from 'react';

import Page from '../components/Page';

function Production() {

    return (
        <Page department="production"></Page>
    );
}

export default Production;