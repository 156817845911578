import React from "react";
//import { Link } from "react-router-dom";

import Page from "../components/Page";
import Card from "../components/Card";
import forms from "../data";

function Digital() {
  const form = forms.filter((item) => item.label === "digital-help-desk");
  const helpDeskForm = form[0];

  return (
    <>
      <div id="form-card-container">
        <Card
          formLink={`/form/${helpDeskForm.id}`}
          formLabel={helpDeskForm.label}
          bgColor={helpDeskForm.color}
          formTitle={helpDeskForm.title}
        />
      </div>
      <Page department="digital"></Page>
    </>
  );
}

export default Digital;
