import React from 'react';
import { Link } from 'react-router-dom';

import logo from '../img/oz-mktg-logo-small.png';

function Header() {
    return (
        <div className="header">
            <Link to="/">
                <img className="logo" src={logo} alt="Oz Mktg" />
            </Link>

            <h1 className="header-text">Forms</h1>
        </div>
    );
}

export default Header;


