import React from "react";
import { Switch, Route } from "react-router-dom";

import App from "./App";
import AllForms from "./pages/AllForms";
import MassMedia from "./pages/MassMedia";
import Design from "./pages/Design";
import Development from "./pages/Development";
import Digital from "./pages/Digital";
// import Print from "./pages/Print";
import Production from "./pages/Production";
import Swag from "./pages/Swag";
import GenOffice from "./pages/GenOffice";
import Coop from "./pages/Coop";
import Pr from "./pages/Pr";

import Form from "./pages/Form";

export default (
  <Switch>
    <Route component={App} exact path="/" />
    <Route component={AllForms} path="/all-forms" />
    <Route component={MassMedia} path="/mass-media" />
    <Route component={Design} path="/design" />
    <Route component={Development} path="/development" />
    <Route component={Digital} path="/digital" />
    <Route component={Production} path="/production" />
    <Route component={Coop} path="/coop" />
    {/* <Route component={Print} path="/print" /> */}
    <Route component={Swag} path="/swag" />
    <Route component={GenOffice} path="/general-office" />
    <Route component={Pr} path="/pr" />
    <Route component={Form} path="/form/:id" />
  </Switch>
);
