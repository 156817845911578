import React from 'react';

import forms from '../data';
import Card from '../components/Card';

function Page(props) {

    const pageForms = forms.filter(form => form.department === props.department)

    return (
        <div id="form-card-container">
            {pageForms.map(form => (
                <Card
                    key={form.id}
                    formLink={`/form/${form.id}`}
                    formLabel={form.label}
                    bgColor={form.color}
                    formTitle={form.title}
                />
            ))}
        </div>
    );
}

export default Page;