import React from "react";

import Page from "../components/Page";

function Development() {
  return (
    <>
      <a
        href="https://oz-marketing.gitbook.io/help-center/"
        target="_blank"
        rel="noopener noreferrer"
        className="container"
      >
        <div className="form-card" style={{ backgroundColor: "#a358df" }}>
          <h2 className="form-card-title">Help Center</h2>
        </div>
      </a>
      <Page department="development"></Page>
    </>
  );
}

export default Development;
