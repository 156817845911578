/**
 *  PLEASE READ
 * Department options (should be all lowercase):
 *      mass media
 *      digital
 *      design
 *      development
 *      production
 *      coop
 *      swag
 *      office
 */

const forms = [
  {
    id: 0,
    title: "Billboard Requests",
    department: "mass media",
    label: "billboard-request",
    url: "https://forms.monday.com/forms/embed/74b5da5770e838b087f813d4d4e68ce7",
    color: "#8fc741",
  },
  {
    id: 2,
    title: "Design Requests",
    department: "design",
    label: "design-request",
    url: "https://forms.monday.com/forms/embed/5a67baf3c4f960116e22df1fbf8cd168?r=use1", 
    color: "#8FC741",
  },
  // {
  //   id: 3,
  //   title: "Dev Support",
  //   department: "development",
  //   label: "dev-support",
  //   url: "https://forms.monday.com/forms/embed/63cdbfc47e6ce588cba376222b23e395",
  //   color: "#E44258",
  // },
  {
    id: 3,
    title: "Dev Support",
    department: "development",
    label: "dev-support",
    url: "https://forms.monday.com/forms/embed/39f8a8eeac041d30c216faeaa8054a18?r=use1",
    color: "#E44258",
  },
 // {
 //   id: 4,
 //   title: "Email",
 //   department: "digital",
 //   label: "email",
 //   url: "https://forms.monday.com/forms/embed/c49d86b5870d479f147b1c4e1b7ae88a",
 //   color: "#394583",
//  },
  {
    id: 7,
    title: "Mailer Requests",
    department: "design",
    label: "mailers",
    url: "https://forms.monday.com/forms/embed/50a19093ca89741087045a958ed9c37c?r=use1",
    color: "#8FC741",
  },
  {
    id: 9,
    title: "Regular Production Requests",
    department: "production",
    label: "production-form",
    url: "https://forms.monday.com/forms/embed/92f4d3dc11aa86bec7f323faacea5916",
    color: "#0073ea",
  },
  {
    id: 10,
    title: "Powersports Social Media Requests",
    department: "production",
    label: "radio-buy",
    url: "https://forms.monday.com/forms/embed/abe5fd48f3ef65bcfb63c77530410c6c",
    color: "#e44258",
  },
  {
    id: 12,
    title: "Swag Requests",
    department: "swag",
    label: "swag-requests",
    url: "https://forms.monday.com/forms/embed/bdd00784f2234412f9d8782618957529",
    color: "#575C96",
  },
  {
    id: 14,
    title: "Signed TV Buys",
    department: "mass media",
    label: "tv-buy",
    url: "https://forms.monday.com/forms/embed/06fdaef95bea80ecd441a219c40d0c1b",
    color: "#575C96",
  },
  {
    id: 17,
    title: "Event Requests",
    department: "design",
    label: "event-requests",
    url: "https://forms.monday.com/forms/embed/f916785dc7860fb1aa7530766a2bcf38",
    color: "#8FC741",
  },
  {
    id: 26,
    title: "Digital Help Desk",
    //DO NOT include a department
    label: "digital-help-desk",
    url: "https://forms.monday.com/forms/embed/e00b2a30ce1780084e32143c4735b0aa",
    color: "#575c96",
  },
  // {
  //   id: 28,
  //   title: "Documentation Requests",
  //   department: "development",
  //   label: "development-request",
  //   url: "https://forms.monday.com/forms/embed/50cc5b5cf57016ca93e61c0ffe49fb64",
  //   color: "#800080",
  // },
  {
    id: 29,
    title: "Pre-Approval Requests",
    department: "coop",
    label: "preapproval-request",
    url: "https://forms.monday.com/forms/embed/d39dd41806dc2e7559e3a28da4e5de39",
    color: "#00ca72",
  },
  {
    id: 31,
    title: "Wizards of Oz Nominations",
    department: "office",
    label: "wizard-nominations",
    url: "https://forms.monday.com/forms/embed/a2642f15b3170cf7ef93ce62bb2cef2a",
    color: "#90c444",
  },
  {
    id: 32,
    title: "Compliance Request Form",
    department: "coop",
    label: "compliance-request",
    url: "https://forms.monday.com/forms/embed/6ae5b85b3f181083f8189a048c645cad",
    color: "#800080",
  },
  {
    id: 33,
    title: "Young Dashboard Post Request",
    department: "pr",
    label: "young-dashboard-post-request",
    url: "https://forms.monday.com/forms/embed/4e7c39f5a1fa3d5892114de1beb7dbba",
    color: "#0073ea",
  },
  {
    id: 34,
    title: "Press Release Request",
    department: "pr",
    label: "press-release-request",
    url: "https://forms.monday.com/forms/embed/285ae80da564e99155a7cdc1af7e77f6",
    color: "#0073ea",
  },
  {
    id: 35,
    title: "Award Submission Request",
    department: "pr",
    label: "award-submission-request",
    url: "https://forms.monday.com/forms/embed/cfe38dc34c05443230adf9262874496d",
    color: "#0073ea",
  },
  {
    id: 36,
    title: "Print Receipts & Updates",
    department: "office",
    label: "print-receipts-updates",
    url: "https://forms.monday.com/forms/embed/f95fb288fb0ca11ba4560ece45d43cec",
    color: "#0073ea",
  },
  {
    id: 37,
    title: "Oz Order Requests",
    department: "office",
    label: "oz-order-requests",
    url: "https://forms.monday.com/forms/embed/43051a27196fbd969e25e5450b1262e1",
    color: "#00ca72",
  },
  {
    id: 38,
    title: "Oz Card Receipts",
    department: "office",
    label: "oz-card-receipts",
    url: "https://forms.monday.com/forms/embed/e4114e1e1a11027db37c4e41853cd84a",
    color: "#00ca72",
  },
  {
    id: 39,
    title: "Oz Billing Updates",
    department: "office",
    label: "oz-billing-updates",
    url: "https://forms.monday.com/forms/embed/73f261999a007d24eb3b7babaca4bcf0",
    color: "#00ca72",
  },
  {
    id: 40,
    title: "Oz Shoes Request",
    department: "office",
    label: "oz-shoes-request",
    url: "https://forms.monday.com/forms/embed/d21ae6eb2e28b94c020f169cbcd19825",
    color: "#00CA72",
  },
  {
    id: 41,
    title: "Vehicle Check In/Out",
    department: "office",
    label: "vehicle-check-in-out-request",
    url: "https://forms.monday.com/forms/embed/781753d2e69b630e1b5537b2683c1601?r=use1",
    color: "#00CA72",
  },
  {
    id: 42,
    title: "Organic Social Media Content",
    department: "pr",
    label: "organic-social-media-content",
    url: "https://forms.monday.com/forms/embed/525667c128194f1652e0b33815b20b63?r=use1",
    color: "#0073ea",
  },
];

export default forms;
