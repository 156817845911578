import React from 'react';
import { Link } from 'react-router-dom';

function Card(props) {
    const { formLink, formLabel, bgColor, formTitle } = props
    return (
        <Link to={formLink}>
            <div className="form-card" id={formLabel} style={{ backgroundColor: `${bgColor}` }}>
                <h2 className="form-card-title">{formTitle}</h2>
            </div>
        </Link>
    );
}

export default Card;