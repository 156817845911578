import React from "react";
import { Link, NavLink } from "react-router-dom";

function quickLinks() {
  const quickLinks = [
    {
      text: "Mass Media",
      route: "/mass-media",
    },
    {
      text: "Design",
      route: "/design",
    },
    {
      text: "Development",
      route: "/development",
    },
    {
      text: "Digital",
      route: "/digital",
    },
    {
      text: "Production",
      route: "/production",
    },
    {
      text: "Co-Op",
      route: "/coop",
    },
    // {
    //   text: "Print",
    //   route: "/print",
    // },
    {
      text: "Swag",
      route: "/swag",
    },
    {
      text: "General Office",
      route: "/general-office",
    },
    {
      text: "PR",
      route: "/pr",
    },
  ].map((link) => (
    <NavLink
      key={link.route}
      to={link.route}
      activeClassName="current-quick-link"
    >
      {link.text}
    </NavLink>
  ));

  return (
    <div style={{ display: "flex", marginTop: "30px" }}>
      <div className="quick-links-container">
        <span className="department-header">Departments</span>
        <span style={{ fontStyle: "italic" }}>
          <Link to="/all-forms">View All Forms</Link>
        </span>
        <div className="quick-links">{quickLinks}</div>
      </div>
    </div>
  );
}

export default quickLinks;
