import React from 'react';

import Page from '../components/Page';

function Swag() {

    return (
        <Page department="swag"></Page>
    );
}

export default Swag;