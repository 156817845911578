import React from 'react';

import forms from '../data';

function Form(props) {

    const { match: { params } } = props
    const targetForm = forms.filter(form => +params.id === form.id)
    const formUrl = targetForm[0].url

    return (
        <div>
            <h1 style={{ textAlign: 'center', fontSize: '1.75rem', fontWeight: '600' }}>{targetForm[0].headerText}</h1>

            <div className="iframe-container">

                <iframe
                    src={formUrl}
                    width="650"
                    height="2700"
                    title="form embed"
                    style={{ border: '0', boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)' }}></iframe>

            </div>
        </div>
    );
}

export default Form;