import React from 'react';

import Page from '../components/Page';

function GenOffice() {

    return (
        <Page department="office"></Page>
    );
}

export default GenOffice;