import React from 'react';

import Page from '../components/Page';

function Design() {

    return (
        <Page department="design"></Page>
    );
}

export default Design;