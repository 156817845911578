import React from 'react';
import routes from './routes';
import { useLocation } from 'react-router-dom';

import './styles/App.css';

import Header from './components/Header';
import QuickLinks from './components/QuickLinks';

function App() {

  let url = useLocation();

  if (!url.search.includes('hidenav')) {
    return (
      <div className="App">
        <Header />

        <QuickLinks />

        {routes}
      </div>
    );
  } else {
    return (
      <div className="App">
        <Header />

        <div className="container">
          <div className="content">
          </div>
        </div>

        {routes}

      </div>
    );

  }

}

export default App;


